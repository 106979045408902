import { ADAPTER_EVENTS, SafeEventEmitterProvider, WALLET_ADAPTER_TYPE } from "@web3auth/base";
import TorusSdk from "@toruslabs/customauth";
import type { LOGIN_PROVIDER_TYPE } from "@toruslabs/openlogin";

import { Web3AuthCore } from "@web3auth/core";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from "../config/chainConfig";
import { WEB3AUTH_NETWORK_TYPE } from "../config/web3AuthNetwork";
import { getWalletProvider, IWalletProvider } from "./walletProvider";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import Web3 from "web3";
import {ethers} from "ethers";
const LOGIN_TYPE_GOOGLE = "google";
const LOGIN_TYPE_EMAIL = "email";
const SUPPORTED_LOGIN_TYPES = [
    LOGIN_TYPE_GOOGLE, LOGIN_TYPE_EMAIL];

let VERIFIER_DICT:any = {
    [LOGIN_TYPE_GOOGLE]: {
        "testnet": "glipgg-google-testnet",
        "mainnet": "glipgg-google-mainnet",
        "name": "Google",
        "clientId": "373196446500-ojt3ko1ghis9pritfhhogohlotut2hv6.apps.googleusercontent.com",
        "typeOfLogin": 'google'
        
    },
    [LOGIN_TYPE_EMAIL]: {
        "testnet":"glipgg-google-testnet",
        "mainnet":"glipgg-google-mainnet"
    }
}

const getGlipTokenData =  () => {
    let glipggToken:any = localStorage.getItem('glipgg-glipggtoken-data');
    if(!glipggToken){
        return;
        }
    glipggToken = JSON.parse(glipggToken);
        if(!glipggToken.id){
            return;
        }
    return glipggToken;
}

export async function saveUserBackend(clientIdentifier:any, signature:any, accessToken:any){
    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/auth/exchange-token', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({
                "signature": signature,
                "accessToken": accessToken,
                "verifier": "youtube",
                "companyId": clientIdentifier,
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}


export async function fetchNFTsBackend(walletId:any){
    let loginInformation = getGlipTokenData();
    console.log(loginInformation);
    try{
        let resp:any = await fetch(`https://be.namasteapis.com/blockchain/v1/wallet-balance/list-wallet-nft-client?walletId=${walletId}&size=50`, {
	    method: 'GET',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    }
        });
        resp = await resp.json();
        console.log('resp.minted', resp,resp.data.minted);
        return resp.data
    }
    catch(e){
        return false;
    }
}

export async function submitTransfer(form:any, signature:any){
    let loginInformation = getGlipTokenData();
    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/token-transfer/submit/', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },
	    body: JSON.stringify({
                chain:'mumbai',
                form:form,
                signature:signature
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}

export async function createTransfer(
    walletIdFrom:any, walletIdTo:any,
    tokenDataId:any, amount:any){
    let loginInformation = getGlipTokenData();
    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/token-transfer/create', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },
	    body: JSON.stringify({
                walletIdFrom:walletIdFrom,
                walletIdTo:walletIdTo,
                tokenDataId:tokenDataId,
                amount:amount
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}


export async function createSellOrder(
    makerWalletId:any, takerWalletId:any,
    makeTokenDataId:any, makeAmount:any,
    takeTokenDataId:any, takeAmount:any){
    let loginInformation = getGlipTokenData();

    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/market/create-sell-order', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },
	    body: JSON.stringify({
                makerWalletId: makerWalletId,
                takerWalletId: takerWalletId,
                makeTokenDataId: makeTokenDataId,
                makeAmount: makeAmount,
                takeTokenDataId: takeTokenDataId,
                takeAmount: takeAmount,
                type: "DIRECT_SALE" // Hardcoded for now
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}



export async function submitSellOrder(sellOrderId:any, signature:any){

    let loginInformation = getGlipTokenData();
    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/market/submit-sell-order/', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },
	    body: JSON.stringify({
                id: sellOrderId,
                signature:signature
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}




export async function createBuyOrder(
    sellOrderId: string,
    makerWalletId:any, takerWalletId:any,
    makeTokenDataId:any, makeAmount:any,
    takeTokenDataId:any, takeAmount:any){
    let loginInformation = getGlipTokenData();

    try{

        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/market/create-bid-order', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },

	    body: JSON.stringify({
                sellOrderId: sellOrderId,
                makerWalletId: makerWalletId,
                takerWalletId: takerWalletId,
                makeTokenDataId: makeTokenDataId,
                makeAmount: makeAmount,
                takeTokenDataId: takeTokenDataId,
                takeAmount: takeAmount,
                // type: "DIRECT_SALE" // Hardcoded for now // Gets it from sellOrder
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}



export async function submitBuyOrder(buyOrderId:any, signature:any){
    let loginInformation = getGlipTokenData();
    try{
        let resp = await fetch('https://be.namasteapis.com/blockchain/v1/market/submit-bid-order/', {
	    method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
                'access-token': loginInformation.accessToken
	    },
	    body: JSON.stringify({
                id: buyOrderId,
                signature:signature
            })
        });
        resp = await resp.json();
        console.log(resp);
        return resp
    }
    catch(e){
        return false;
    }
}


// ---------------------------------------------------------------- //

export async function buyNFTBackend(
    signer: any,
    sellOrderId: any,
    makerWalletId:any,
    makeTokenDataId:any, makeAmount:any,
    takeTokenDataId:any, takeAmount:any){
    let resp:any = await createBuyOrder(
        sellOrderId,
        makerWalletId, makerWalletId,
        makeTokenDataId, makeAmount,
        takeTokenDataId, takeAmount);
    let data = JSON.parse(resp.data.form);

    console.log(data);
    // console.log(await signer.getAddress());
    console.log(signer.address);
    console.log(signer.privateKey);

    const sig = await signer._signTypedData( data[ "domain" ], data[ "types" ], data[ "message" ] );

    await submitBuyOrder(resp.data.id, sig);

}


export async function sellNFTBackend(
    signer: any,
    makerWalletId:any,
    makeTokenDataId:any, makeAmount:any,
    takeTokenDataId:any, takeAmount:any){
    let resp:any = await createSellOrder(
        makerWalletId, makerWalletId,
        makeTokenDataId, makeAmount,
        takeTokenDataId, takeAmount);
    let data = JSON.parse(resp.data.form);
    const sig = await signer._signTypedData( data[ "domain" ], data[ "types" ], data[ "message" ] );
    await submitSellOrder(resp.data.id, sig);
    console.log(resp.data.form);
}

export async function transferNFTBackend(
    walletIdFrom:any, walletIdTo:any,
    tokenDataId:any, signer:any, amount:any){
    let resp:any = await createTransfer(
        walletIdFrom, walletIdTo, tokenDataId, amount);
    let data = JSON.parse(resp.data.form);
    const sig = await signer._signTypedData( data[ "domain" ], data[ "types" ], data[ "message" ] );
    await submitTransfer(resp.data.form, sig)
    console.log(resp.data.form);
}

async function loginUserBackend(
    clientIdentifier: string, privKey: string, accessToken: string) {
    console.log(privKey, accessToken);;
    let provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/f9f9c829617b43248215db1314a0fbd9")
    let wallet = new ethers.Wallet(privKey)
    let signer = wallet.connect(provider)
    let signature = await signer.signMessage(accessToken);
    console.log(signature);
    let tokenData:any = await saveUserBackend(clientIdentifier, signature, accessToken);
    console.log('tokenData.data', tokenData.data);
    return tokenData.data;
}


export async function saveWalletAddress(
    userId:any, publicAddress:any){
    try{
        let resp = await fetch(`https://be.namasteapis.com/blockchain/v1/external/link?walletId=${userId}`, {
	    method: 'PUT',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
	    },
	    body: JSON.stringify({
                externalAddress: publicAddress
            })
        });
        resp = await resp.json();
        return resp
    }
    catch(e){
        return false;
    }    
}

/* 
 * const verifierMap = {
 *   [GOOGLE]: {
 *       name: "Google",
 *       typeOfLogin: "google",
 *       verifier: "glipgg-google-testnet",
 *       clientId: "373196446500-ojt3ko1ghis9pritfhhogohlotut2hv6.apps.googleusercontent.com",
 *   }
 *     [TWITCH]: {
 *         name: "Twitch",
 *         typeOfLogin: "twitch",
 *         clientId: "617201755556395",
 *         verifier: "twitch-lrc",
 *     },
 * };
 *  */
export interface IWeb3AuthContext {
    web3Auth: Web3AuthCore | null;
    provider: IWalletProvider | null;
    isLoading: boolean;
    user: unknown;
    login: (adapter: WALLET_ADAPTER_TYPE,provider: LOGIN_PROVIDER_TYPE, login_hint?: string) => Promise<void>;
    logout: () => Promise<void>;
    getUserInfo: () => Promise<any>;
    signMessage: () => Promise<any>;
    getAccounts: () => Promise<any>;
    getBalance: () => Promise<any>;
}

const uiConsole = (...args: unknown[]): void => {
    const el = document.querySelector("#console>p");
    if (el) {
        el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
};

export const checkIfRedirectURI = ()=>{
    if(window.location.pathname.includes('wallet-login') && (
        window.location.hostname === 'www.glip.gg' ||
        window.location.hostname === 'glip.gg'
    )){return true;}
    return false;
}

const checkIfLoginURI = () => {
    var hash = window.location.hash.substr(1);
    let result:any = hash.split('&').reduce(function (
        res:any, item:any) {
        var parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
    }, {});
    if(result.id_token && result.token_type && result.state){return true;};
    return false;
}

const saveLoginDetails = (
    loginDetails:any, redirectState:any, glipGGToken:any)=> {
        console.log(loginDetails);
        console.log(redirectState);
        localStorage.setItem(
            'glipgg-wallet-user-info', JSON.stringify(
                loginDetails.result));
        localStorage.setItem(
            'glipgg-public-address', loginDetails.result.publicAddress);
        localStorage.setItem(
            'glipgg-glipggtoken-data', JSON.stringify(glipGGToken));
        
}

const getLoginInformation = () => {
    let loginInformation:string = localStorage.getItem(
        'glipgg-wallet-user-info')!;
    return JSON.parse(loginInformation);
}

const checkIfLoggedIn = () => {
    let loginInformation:string = localStorage.getItem(
        'glipgg-wallet-user-info')!;
    if(!loginInformation){
        return false;
    }
    let loginInfoParsed = JSON.parse(loginInformation);
    if(loginInfoParsed.userInfo && loginInfoParsed.privateKey){
        return true;
    }
}

const GlipAuthGlobal = (() => {
    let clientIdentifier: any;
    let torusdirectsdk:any;
    let onLoginChange = (loggedIn:any)=>{console.log(loggedIn)};
    let web3AuthNetwork:any = undefined;
    let isLoadingChangeSubscribers:any = [];
        
    const getSigner = (privKey:any) => {
        let provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/f9f9c829617b43248215db1314a0fbd9")
        let wallet = new ethers.Wallet(privKey)
        let signer = wallet.connect(provider)
        return signer;
    }

    const _getGlipWalletData = ()=>{
        let walletDetails:any = localStorage.getItem(
            'glipgg-wallet-user-info');
        if(!walletDetails){
            return;
        }
        walletDetails = JSON.parse(walletDetails);
        if(!walletDetails){
            return;
        }
        return walletDetails;
    }

    async function initializeTorus(){
        try {
            torusdirectsdk = new TorusSdk({
                baseUrl:'https://glip.gg/wallet-login/',
                enableLogging: true,
                network: web3AuthNetwork, // details for test net
                uxMode: "redirect",
                redirectPathName: ''
            });
            await torusdirectsdk.init({ skipSw: true, skipPrefetch:true });
        } catch (error) {
            console.error(error, "mounted caught");
        }
    }
    
    async function init(
        clientId:any,
        tWeb3AuthNetwork:any,
        chainName:string,
        tonLoginChange:any) {
        clientIdentifier = clientId;
        console.log('chainName', chainName);
        onLoginChange = tonLoginChange;
        console.log(
            'checkIfRedirectURI()',
            checkIfRedirectURI()
        );
        console.log('doing init');
        web3AuthNetwork =tWeb3AuthNetwork;
        initializeTorus();
        if(checkIfRedirectURI()){
            const loginDetails = await torusdirectsdk.getRedirectResult();
            
            let queryString = Object.keys(loginDetails.hashParameters).map(key => key + '=' + loginDetails.hashParameters[key]).join('&');
            let lastLocation:string = loginDetails.state.lastlocation;
            console.log('loginDetails', loginDetails);
            console.log(lastLocation + `#${queryString}`);
            window.location.href = lastLocation + `#${queryString}`
            console.log('loginDetails', loginDetails);
            return;
        }
        if(checkIfLoginURI()){
            var hash = window.location.hash.substr(1);
            let result:any = hash.split('&').reduce(function (
                res:any, item:any) {
                var parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});
            console.log('result', result);
            if(result.state && result.id_token && result.token_type){
                const loginDetails = await torusdirectsdk.getRedirectResult();
                let glipggToken = await loginUserBackend(
                    clientIdentifier,
                    loginDetails.result.privateKey,
                    loginDetails.hashParameters.access_token
                );
                saveLoginDetails(loginDetails, result, glipggToken);
                onLoginChange(true);
            }
            return;
        }
        if(checkIfLoggedIn()){
            let loginInformation = getLoginInformation();
            onLoginChange(true);
            console.log(
                loginInformation.idToken,
                loginInformation.verifier,
                loginInformation.verifierId
            );
            return;
        }
        
        return;
    }

    const logout = async () => {
        localStorage.setItem('glipgg-wallet-user-info', '');
        localStorage.setItem('glipgg-public-address', '');
        onLoginChange(false);
    };

    // TODO: Implement this.
    const getAccounts = async () => {};
    
    const getUserInfo = async () => {
        let walletInfo = _getGlipWalletData();
        if(!walletInfo){
            throw('User not loggedin');
        }
        return walletInfo.userInfo;
    };

    const login = async (loginType:string) => {
        console.log(loginType);
        let loginData  = {
            name: VERIFIER_DICT[loginType]['name'],
            typeOfLogin: VERIFIER_DICT[loginType]['typeOfLogin'],
            verifier: VERIFIER_DICT[loginType][web3AuthNetwork],
            clientId: VERIFIER_DICT[loginType]['clientId'],
            customState: {
                'lastlocation':window.location.href
            }
        }
        const loginDetails = await torusdirectsdk.triggerLogin(
            loginData);
        console.log('loginDetails', loginDetails);
        return;
    };


    const onIsLoadingChange = (func:any) => {
        isLoadingChangeSubscribers.push(func);
    }

    const showConnectModal = (loginTypeArr:any[]) => {
        console.log(loginTypeArr);
        if(!loginTypeArr){
            throw('Need to pass an array');
        }
        if(loginTypeArr.length < 1){
            throw(
                'Need to have atleast one element in login array'
            );
        }
        for(let i=0;i<loginTypeArr.length;i++){
            if(!SUPPORTED_LOGIN_TYPES.includes(loginTypeArr[i])){
                throw(`${loginTypeArr[i]} Login not supported`);
            }
        }
        if(!loginTypeArr[0]){return;}
        var event = new CustomEvent(
            "show-glip-modal",
            {detail: {loginTypeArr: loginTypeArr}}
        );
        document.dispatchEvent(event);
    }

    const hideConnectModal = (func:any) => {
        var event = new CustomEvent(
            "hide-glip-modal");
        document.dispatchEvent(event);
    }    

    const getTorusDirectSDK =  () =>{
        return torusdirectsdk;
    }

    const _mergeTokenDataAndNFTs = (nftBackendResponse:any)=>{
        let tempNFTData = [];
        let tokenDataDict:any = {};
        nftBackendResponse.tokens.forEach((x:any) => {
            tokenDataDict[x._id]=x;
        });
        for(let i=0;i<nftBackendResponse.minted.length;i++){
            let tNDT:any = nftBackendResponse.minted[i];
            console.log(tokenDataDict, tNDT.tokenDataId,
                        tokenDataDict[String(tNDT.tokenDataId)]);
            tNDT.tokenData = (tokenDataDict[tNDT.tokenDataId]);
            tempNFTData.push(tNDT);
        }
        return tempNFTData;
    }

    const fetchNFTs = async () =>{
        let glipggToken:any = localStorage.getItem('glipgg-glipggtoken-data');
        if(!glipggToken){
            return;
        }
        glipggToken = JSON.parse(glipggToken);
        if(!glipggToken.id){
            return;
        }
        let nftBackendResponse:any = await fetchNFTsBackend(
            glipggToken.id);
        if(nftBackendResponse){
            let mergedData = _mergeTokenDataAndNFTs(
                nftBackendResponse);
            console.log('mergedData', mergedData);
            return mergedData;
        }
        return []
    }

    const getWalletID = () =>{
        let glipggToken:any = localStorage.getItem('glipgg-glipggtoken-data');
        if(!glipggToken){
            return;
        }
        glipggToken = JSON.parse(glipggToken);
        if(!glipggToken.id){
            return;
        }
        return glipggToken.id
    }
    
    const transferNFT = async (walletIdTo:any, tokenDataId:any, amount:any) => {
        let walletIdFrom = getWalletID();
        let walletDetails:any = _getGlipWalletData();
        let signer = getSigner(walletDetails.privateKey);
        transferNFTBackend(
            walletIdFrom, walletIdTo, tokenDataId,
            signer, amount
        );
    };

    const sellNFT = async (makeTokenDataId:any, makeAmount:any, takeTokenDataId:any, takeAmount:any) => {
        let makerWalletId = getWalletID();
        let walletDetails:any = _getGlipWalletData();
        let signer = getSigner(walletDetails.privateKey);
        sellNFTBackend(
            signer,
            makerWalletId,
            makeTokenDataId, makeAmount,
            takeTokenDataId, takeAmount
        );
    };

    const buyNFT = async (sellOrderId: any, makeTokenDataId:any, makeAmount:any, takeTokenDataId:any, takeAmount:any) => {
        let makerWalletId = getWalletID();
        let walletDetails:any = _getGlipWalletData();
        let signer = getSigner(walletDetails.privateKey);
        buyNFTBackend(
            signer,
            sellOrderId,
            makerWalletId,
            makeTokenDataId, makeAmount,
            takeTokenDataId, takeAmount
        );
    };
    

    const getGlipTokenData =  () => {
        let glipggToken:any = localStorage.getItem('glipgg-glipggtoken-data');
        if(!glipggToken){
            return;
        }
        glipggToken = JSON.parse(glipggToken);
        if(!glipggToken.id){
            return;
        }
        return glipggToken;
    }
    
    return {
        init,
        showConnectModal,
        hideConnectModal,
        login,
        logout,
        getAccounts,
        getUserInfo,
        onIsLoadingChange,
        getTorusDirectSDK,
        getWalletID,
        fetchNFTs,
        transferNFT,
        sellNFT,
        buyNFT,
        getGlipTokenData
    };
    
})();

(window as any).glipAuthGlobal = GlipAuthGlobal;

export default GlipAuthGlobal;
