import { useEffect, useState} from "react";

import { IWalletProvider } from "../services/walletProvider";
import { WALLET_ADAPTERS } from "@web3auth/base";

import Loader from "./Loader";
import styles from "../styles/Home.module.css";
import GlipAuthGlobal,{ checkIfRedirectURI} from '../services/web3AuthGlobal';

import GoogleLogo from "../styles/assets/google_logo.svg";
import TwitchLogo from "../styles/assets/twitch_logo.svg";
import GlipWalletLogo from "../styles/assets/glip_wallet_logo.svg";

const Main = () => {
    
    /* const { provider, login, logout, getUserInfo,
     *         getAccounts, getBalance,
     *         signMessage, isLoading } = useWeb3Auth(); */

    const [provider, setProvider] = useState<IWalletProvider | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    
    useEffect(()=>{
        document.addEventListener(
            "show-glip-modal", function(e:any) {
                console.log(e.detail.loginTypeArr);
                setShow(true)
        });
        document.addEventListener(
            "hide-glip-modal", function(e) {
                setShow(false)
        });
        GlipAuthGlobal.onIsLoadingChange(
            (isLoading:any) => {
                console.log('isLoading');
                setIsLoading(isLoading)
        });
        console.log('checking redirect');
        if(checkIfRedirectURI()){
            GlipAuthGlobal.init(
                '62335548760f50d618bc7b35', 'testnet', 'polygon', ()=>{}
            );
        }
    },[])
    
    const loggedInView = (
        <>
            <button onClick={GlipAuthGlobal.getAccounts} className={styles.card}>
                Get Accounts
            </button>
            <button onClick={GlipAuthGlobal.logout} className={styles.card}>
                Log Out
            </button>
            <div className={styles.console} id="console">
                <p className={styles.code}></p>
            </div>
        </>
    );

    const unloggedInView = (
        <div>
            <button onClick={()=>GlipAuthGlobal.login("google")} className={styles.google_card}>
                <img src={'https://live-nft-hosted-assets.s3.ap-south-1.amazonaws.com/google_logo.svg'}
                     style={{marginRight:14}} />
                <span className={styles.button_text_span}>Continue with Google</span>
            </button>
            {/* <button
                style={{marginTop:12}}
                onClick={()=>GlipAuthGlobal.login("twitch")}
                className={styles.twitch_card}>
                <img src={TwitchLogo}
                style={{marginRight:14}} />
                <span className={styles.button_text_span}>Continue with Twitch</span>
                </button> */}
        </div>  
    );

    if(!show){
        return (<></>)
    }
    const LoaderComp = ()=>(
        <div className={styles.centerFlex}>
            <Loader></Loader>
        </div>);
    
    const MainComp = ()=>(
        <><div className={styles.grid}>
            {unloggedInView}
        </div></>
    );
    
    return (
        <div className={styles.container}>
            <div style={{
                display:'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <h1 className={styles.header_text}>
                    Create a wallet <br/>
                    to continue</h1>
            </div>
            <MainComp/>
            <div className={styles.footer}>
                <span className={styles.footer_normal_text}>powered by</span>
                <img src={'https://live-nft-hosted-assets.s3.ap-south-1.amazonaws.com/glip_wallet_logo.svg'} style={{
                    marginLeft:12,
                    marginRight:6
                }} />
                <span className={styles.footer_big_text}>glipwallet</span>
            </div>
        </div>
    );
    
};

export default Main;
