import { useEffect } from "react";
import Main from "./components/Main";


function App() {
    
    /* useEffect(()=>{
     *     (async()=>{
     *         await (window as any).glipAuthGlobal.init('testnet');
     *         //(window as any).glipAuthGlobal.showModal();
     * })();
     *     
     * },[]); */
    
    return (<Main/>);
}

export default App;
